.contact {
    display: flex;
    align-items: center;
    padding: 2rem;
    gap: 20px;
}


.contact-info,
.contact-form {
    flex-basis: 50%;
}

.contact-info ul {
    list-style: none;
    padding-left: 0;

}

.contact-info li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: .5rem;
}

.contact-info ul span {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-info a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    letter-spacing: .05rem;
}

.form {
    background-color: #ED6A5E;
    display: flex;
    align-items: center;
    justify-content: center; 
}

.form form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 2rem;
    gap: 20px;
    width: 100%;
}

form input[type=text],
form input[type=email],
textarea {
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    height: 2rem;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: white;
}

form input[type=text]::placeholder ,
form input[type=email]::placeholder,
textarea::placeholder {
    color: white;
}

form input[type=submit] {
    color: white;
    font-size: 1.2rem;
    padding: 1rem 0;
    border: 2px solid white;
    background-color: transparent;
    cursor: pointer;
}

form textarea {
    height: 6rem;
    font-family: inherit;
}

form input:focus,
form textarea:focus {
    outline: none;
}

form input.error,
form textarea.error {
    border-bottom: 2px solid red;
}

form span {
    text-align: center;
    padding-bottom: 0;
}



@media only screen and (max-width: 1020px) {
    .contact {
        flex-direction: column;
    }
    .contact-info {
        padding: 0 2rem;
    }
    .contact-form {
        width: 70%;
    }
}

@media only screen and (max-width: 800px) {
    .contact-form {
        width: 90%;
    }
}

