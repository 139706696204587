.nav-section {
    width: 8%;
    position: fixed;
    background-color: #414535;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3% 0;
}



.nav-section button,
.menu button,
.menu ul li button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.menu {
    position: fixed;
    z-index: 150;
    top: 0;
    left: 0;
    background-color: #ED6A5E; 
    height: 100%;
    width: 20%;
    left: -100%;
    transition: all .4s;
}

.menu-active {
    left: 0;
}

.menu svg {
    position: absolute;
    top: 2%;
    left: 5%;
}

.menu ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 5rem;
}

.menu ul li {
    list-style-type: none;
    text-transform: uppercase;
    color: white;
}

.menu button {
    color: white;
    font-size: 1.3rem;
    text-transform: capitalize;
    padding: .8rem 2rem;
    font-weight: 600;
    letter-spacing: .1rem;
}

/* Mobile optimization */

@media only screen and (max-width: 1020px) {
    .nav-section {
        padding-left: 2rem;
        width: 100%;
        height: 8%;
        align-items: flex-start;
        justify-content: center;
        z-index: 100;
    }
    .menu {
        width: 30%;
    }
}

@media only screen and (max-width: 950px) {
    .menu {
        width: 50%;
    }
}

@media only screen and (max-width: 550px) {
    .menu {
        width: 80%;
    }
}

@media only screen and (max-width: 550px) {
    .menu {
        width: 100%;
    }
}

