.experience-section {
    display: flex;
    padding: 2rem;
    gap: 100px;
}

.experience-section > div {
    flex-grow: 1;
}

/* Timeline styling */
.timeline {
    margin-right: 0;
    display: flex;
    flex-direction: column;
}

.timeline .container {
    display: flex;
    gap: 20px;
}

.timeline .info {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 3rem;
}

.timeline .info::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10%;
    background-color: white;
    width: 2px;
    height: 100%;
    transform: translatex(-50%);
}

.timeline .info::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border:  2px solid white;
    transform: translate(-50%, -50%);
}
.timeline .info span {
    font-weight: 500;
    padding-bottom: 0;
    color: #ED6A5E;
}

.timeline .info p,
.timeline .info span {
    padding-bottom: 0;
}

/* Cover Letter section */
.cover-letter  {
    padding: 0 2rem;
}

.cover-letter span {
    
    display: block;
    margin-bottom: .5rem;
}


@media only screen and (max-width: 1000px) {
    .experience-section {
        flex-direction: column;
    }

    .experience-section .experience {
        padding: 0 2rem;
    }
}

