/* 
Rifle Green: #414535;
Cultured: #F1EDEE;
BitterSweet: #ED6A5E;
 */

.App {
  position: relative;
}



