.introduction {
    display: flex;
    height: 100vh;
    background-color: #F1EDEE;
    color: #414535;
}

.introduction__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4rem;
}

.introduction__text span {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .1rem;
    margin  : 0;
    color: #414535;
}

.introduction__text p {
    margin-right: 3rem;
    color: #414535;
}

.introduction__text .social-icons {
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
    color: #414535;
    position: relative;
};

.introduction__text a::before {
    content: "";
    border: 1px solid black;
    position: absolute;
    width: 5rem;
    height: 10px;
    left: 0;
    top: 0;
    background-color: #414535;
}


.introduction__text a:focus {
    text-decoration: none;
    border: none;
    border-style: none;
}

.introduction__text div {
    margin-top: 0;
    display: flex;
    gap: 20px;
}

.introduction__image {
   width: 50%;
}

.introduction__image img {
    height: 100%;
    width: auto;
}


/* Moble Optomizaton */

@media only screen and (max-width: 770px) {
    .introduction__image {
        display: none;
    }
}

@media only screen and (max-width: 475px) {
    .introduction__text div {
        flex-direction: column;
    }
}

@media only screen and (max-width: 377px) {
    .introduction__text h1 {
        font-size: 3rem;
    }
    .introduction__text p {
        margin-right: 0;
    }
}

/* Contact me button styling */

.contact {
    margin-bottom: 1rem;
}

.contact-button {
    text-decoration: none;
    text-transform: uppercase;
    display: inline;
    font-size: 1.2rem;
    background-color: transparent;
    border: 2px solid #ED6A5E;
    padding: .8rem 1.5rem;
    font-weight: 500;
    border-radius: 25px;
    color: #ED6A5E;
    cursor: pointer;
    position: relative;
    z-index: 10;
}

.contact-button::before {
    position: absolute;
    z-index: 5;
    content: '';
    top: 0;
    left: 0;
    border: 2px solid #eba7a1;
    border-radius: 25px;    
    height: 100%;
    width: 100%;
    opacity: 0;
}

.contact-button:hover::before {
    animation-name: buttonHover;
    animation-duration: .5s;
}

@keyframes buttonHover {
    1% { opacity: 1 }
    100% { opacity: 0; scale: 1.4;}
}