.nav-section {
    width: 25%;
    position: fixed;
    background-color: #414535;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
}


.page-numbers {
    display: flex;
    margin: 20%;
}

.page-numbers p {
    font-size: 1.2rem;
    font-weight: 500;
    color: white
}

.page-numbers span {
    content: ' ';
    height: 2px;
    width: 8rem;
    background-color: white;
    margin: auto;
    border-radius: 2px;
}

.description-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.description {
    height: auto;
}

.description h3 {
    margin-bottom: .2rem;
    text-transform: uppercase;
}

.description p {
    color: #F1EDEE;
    letter-spacing: .1rem;
    text-decoration: none;
    overflow: hidden;
}

.description:last-child p,
.description:nth-last-child(2) p {
    cursor: pointer;
    position: relative;
}
.description:last-child p::after,
.description:nth-last-child(2) p::after {
    position: absolute;
    content: ' ';
    height: 2px;
    width: 100%;
    background-color: white;
    bottom: 0;
    left: -100%;
    z-index: 100;
    margin-top: .2rem;
    transition: .5s;
}

.description:last-child p::after {
    width: 40%;
}

.description:last-child p:hover::after,
.description:nth-last-child(2) p:hover::after {
 left: 0%;
}

.nav-section img {
    margin: 0 auto;

}

.signoff {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 1rem;
}

.page-number {
    font-weight: 600;
    font-size: 2.5rem;
    margin: 0 auto;
    color: white;
}

