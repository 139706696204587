.projects {
    padding-right: 3rem;
}


.projects .project-grid {
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: 1fr 1fr;
justify-items: stretch;
height: inherit;
gap: 20px;
height: 700px;
}

.project-grid .project {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: transform .4s;
}

.project-grid .project:hover > * {
    transform: translateX(100%);

}

.project-grid .project a {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    background-color: #ED6A5E;
    left: -100%;
    transition: all .5s;
}



 .project div {
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: all .5s;
}

.one {
    background-image: url(../../assets/better-bank.webp);
}

.two {
    background-image: url(../../assets/weather-app.webp);
}

.three {
    background-image: url(../../assets/lexie-drew.webp);
}

.four {
    background-image: url(../../assets//haze.webp);
}

.five {
    background-image: url(../../assets//lana.webp);
}

.six {
    background-image: url(../../assets/arch.webp);
}

@media only screen and (max-width: 1281px) {
    .project-grid .project a { 
        left: 0%;
    }

    .project-grid .project:hover > * {
        transform: none;
    
    }
 }


@media only screen and (max-width: 1100px) {
    .projects {
        padding: 0 2rem;
    }
    .projects .project-grid {
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr 1fr;
        height: 1300px;
    }

}

@media only screen and (max-width: 830px) {
    .projects .project-grid {
        grid-template-columns: 1fr  ;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        height: 3000px;
    }
}










