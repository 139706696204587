body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;  
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,h2,h3,h4,h5,h6, p {
  margin: 0;
}

section {
  background-color: #414535;
}

 h1 {
  font-size: 5rem;
  font-weight: 700;
  margin: 1rem 0;
}

h3 {
  margin-bottom: .2rem;
  text-transform: uppercase;
  color: #ED6A5E;
}

h4 {
  font-size: 2rem;
  padding: 2rem 0;
  color: #ED6A5E;
}

h5 {
  font-size: 1.5rem;
  padding: 1rem 0;
  color: #ED6A5E;
}

h6 {
  font-size: 1.2rem;
  color: white;
}

main p,
main span {
  line-height: 1.5em;
  letter-spacing: .05rem;
  font-size: 1rem;
  color: whitesmoke;
  padding-bottom: 2rem;
}



