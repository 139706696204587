.technology {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 5rem 0;
}

.technology .tech-stack {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 15%;
    gap: 90px;
}   

.tech-stack div{
    height: 110px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.tech-stack div{
    /* width: 200px;
    height: 200px; */
    font-size: 4rem;
}

.tech-stack div h6 {
    font-size: 1.6rem;
}

.tech-stack svg {
    fill: white;
}
/* 
.tech-stack div:last-of-type svg {
    fill: transparent;
}

.tech-stack div:last-of-type svg path {
    color: white;
} */

@media only screen and (max-width: 1000px) {
.technology h4 {
    padding: 0 4rem;
}
}